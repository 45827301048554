
import logo from './logo.png';
import logoRing from './logo-ring.png';
import './App.css';
import './FrizQuadrataPro-Regular.otf'
import React, {Component, useState} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from 'react-router-dom';
import SiteBlack from "./components/siteBlack";



class App extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route exact path='/' element={< SiteBlack logo={logo}/>}></Route>
                    <Route exact path='/white-defaultlogo' element={< SiteBlack logo={logo} black={false}/>}></Route>
                    <Route exact path='/black-ringlogo' element={< SiteBlack logo={logoRing}/>}></Route>
                    <Route exact path='/white-ringlogo' element={< SiteBlack logo={logoRing} black={false}/>}></Route>
                </Routes>

            </Router>
        );
    }
}

{/*

function App() {
  const [language, setLanguage] = useState("DE");


  return (
    <div className="App">
        <p>
            <a onClick={ () => setLanguage("DE") } className="App-link">German</a> | <a onClick={ () => setLanguage("EN")} className="App-link">English</a>
        </p>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
          {(language==="EN")
              && <p className="text">
                  Golden Sun LLC is a premium trading and distribution company for luxury and fine goods based in Zug, Switzerland.<br/>
                  Our main markets are Switzerland and EU countries. We also trade selected products in international markets.<br/>
                  With more than 30 years of experience in wholesale, retail and distribution you will benefit having access to our first class network.<br/>
                  For any inquiries on premium products or bespoke market access strategies please contact our sales team.
              </p>
          }
          {(language==="DE")
              && <p className="text">
                  Golden Sun LLC ist ein Premium Handels- und Vertriebsunternehmen für Luxus- und Feinwaren mit Sitz in Zug, Schweiz.<br/>
                  Unsere Hauptmärkte sind die Schweiz und die EU-Länder. Ausgewählte Produkte handeln wir auch auf internationalen Märkten.<br/>
                  Mit mehr als 30 Jahren Erfahrung im Groß- und Einzelhandel und im Vertrieb profitieren Sie von unserem erstklassiges Netzwerk.<br/>
                  Für Anfragen zu Premium Produkten oder kundenspezifischen Marktzugängen gibt Ihnen unser Verkaufs-Team gerne Auskunft.
              </p>
          }
      </header>
      <main>

      </main>
    </div>
  );
}*/}

export default App;
