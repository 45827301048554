import '../App.css'
import {useState} from "react";

function SiteBlack( { logo, black=true } ) {
    const [language, setLanguage] = useState("DE");


    return (
        <div className={black ? "App" : "AppWhite"}>
            <div className="langMenu">
                <p className="pTop">
                    <a onClick={ () => setLanguage("DE") } className="App-link">German</a> | <a onClick={ () => setLanguage("EN")} className="App-link">English</a>
                </p>
            </div>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                {(language==="EN")
                    && <p className="text">
                        Golden Sun LLC is a premium trading and distribution company for luxury and fine goods based in Zug, Switzerland.<br/>
                        Our main markets are Switzerland and EU countries. We also trade selected products in international markets.<br/>
                        With more than 30 years of experience in wholesale, retail and distribution you will benefit having access to our first class network.<br/>
                        For any inquiries on premium products or bespoke market access strategies please contact our sales team.
                    </p>
                }
                {(language==="DE")
                    && <p className="text">
                        Golden Sun LLC ist ein Premium Handels- und Vertriebsunternehmen für Luxus- und Feinwaren mit Sitz in Zug, Schweiz.<br/>
                        Unsere Hauptmärkte sind die Schweiz und die EU-Länder. Ausgewählte Produkte handeln wir auch auf internationalen Märkten.<br/>
                        Mit mehr als 30 Jahren Erfahrung im Groß- und Einzelhandel und im Vertrieb profitieren Sie von unserem erstklassiges Netzwerk.<br/>
                        Für Anfragen zu Premium Produkten oder kundenspezifischen Marktzugängen gibt Ihnen unser Verkaufs-Team gerne Auskunft.
                    </p>
                }
                <p>
                    <p className="text"> Mail <br/> <a className="App-link" href="mailto:office@golden-sun.ch">office@golden-sun.ch </a></p>


                </p>
            </header>

            <footer  className="footer">
                <p className="noMargin"> Golden Sun Trading by Martin V. Schuster, Gubelstrasse 15, 6300 Zug, CH</p>
            </footer>
        </div>
    );
}

export default SiteBlack;